import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DEFAULT_LANGUAGE } from "./../../constants";

export interface ApplicationState {
  moveDownPopups: boolean;
  onlineMode: boolean;
  isStepFreeRoute: boolean;
  language: string;
  controlsAlwaysLow: boolean;
}

const initialState: ApplicationState = {
  moveDownPopups: false,
  onlineMode: false,
  isStepFreeRoute: false,
  language: DEFAULT_LANGUAGE,
  controlsAlwaysLow: false,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setMoveDownPopups: (state, action: PayloadAction<boolean>) => {
      state.moveDownPopups = action.payload;
    },
    setOnlineMode: (state, action: PayloadAction<boolean>) => {
      state.onlineMode = action.payload;
    },
    setIsStepFreeRoute: (state, action: PayloadAction<boolean>) => {
      state.isStepFreeRoute = action.payload;
    },
    // Currently not in use because a primary language is not returned from the API
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setControlsAlwaysLow: (state, action: PayloadAction<boolean>) => {
      state.controlsAlwaysLow = action.payload;
    },
  },
});

export const {
  setMoveDownPopups,
  setOnlineMode,
  setIsStepFreeRoute,
  setLanguage,
  setControlsAlwaysLow,
} = applicationSlice.actions;

export default applicationSlice.reducer;
