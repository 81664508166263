import { DEFAULT_LANGUAGE } from "../constants";
import { LanguageObject } from "../redux/services/config";

export const parseLanguageObject = (
  languageObject: LanguageObject[],
  language: string,
): string | null =>
  (
    languageObject.find(({ lang }) => lang === language) ||
    languageObject.find(({ lang }) => lang === DEFAULT_LANGUAGE)
  )?.text || null;
